//Strict Mode 
(function ($) {
    "use strict";

    //Run on Document Ready
    $(document).ready(function () {

        //Set propper email addresses
        $('a[data-method="liame"]').each(function () {
            var name = $(this).data('n');
            var domain = $(this).data('d');
            var email = name + '@' + domain;

            $(this).attr('href', 'mailto:' + email).html(email);
        });

        //Smooth scrool
        //$("html").niceScroll({styler:"fb",cursorcolor:"#000"});

        //Side menu - Open
        $('.side-menu-open').mouseenter(function () {
            $('.side-menu').animate({'left': '0px'}, 600, 'easeOutCubic');
        });

        //Side menu - Close
        $('#side-menu-close').click(function (e) {
            var sideWidth = $('.side-menu').outerWidth();
            var sideWidthClose = '-' + sideWidth + 'px';
            $('.side-menu').animate({'left': sideWidthClose}, 600, 'easeOutCubic');
            e.preventDefault();
        });

        if (window.location.pathname != '/') {
            console.info('Direct page access...');
            moveToSection("#" + window.location.pathname.slice(1));
        }

        // Scroll to anchor links
        $('a[data-target]').on('click', function () {
            var self = this;
            //var new_location = self.pathname.replace(location.pathname, '');
            var new_location = self.pathname;
            var hash = $(self).attr('data-target');
            var target = $(hash);

            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                window.history.pushState({hash: hash}, 'Go to ' + hash, new_location);

                setMetas(hash.slice(1));

                moveToSection(hash);

                return false;
            }
        });

        // An event handler for the popstate event on the window
        window.onpopstate = function (e) {
            e.preventDefault();

            try {
                var hash = e.state.hash;

                moveToSection(hash);
            } catch (err) {
                console.warn('Hash not set!');
            }
        };

        /**
         * Scroll page to specific section
         *
         * @param hash
         */
        function moveToSection(hash) {
            var target = $(hash);

            target = target.length ? target : $('[name=' + hash.slice(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 700, 'easeInOutExpo', function () {
                    // Set hash in the address bar because of the hash bang
                });
            }
        }

        /**
         * Load page new metas
         *
         * @param slug
         */
        function setMetas(slug) {
            $.ajax({
                method: "GET",
                url: "meta/" + slug,
                data: {}
            }).done(function (response) {
                $("title").html(response.title);
                $("meta[name='description']").attr('content', response.description);
                $("meta[name='keywords']").attr('content', response.keywords);
            }).fail(function (jqXHR, textStatus) {
                console.error(jqXHR, textStatus);
            });
        }

        //$('a[href*=#]:not([href=#])').click(function() {
        //  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        //    // Remove the hash bang
        //    var self = this;
        //    var hash = this.hash.replace(/\!\//, '');
        //    var target = $(hash);
        //    //var target = $(this.hash);
        //
        //    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        //
        //    if (target.length) {
        //      $('html,body').animate({
        //        scrollTop: target.offset().top
        //      }, 700, 'easeInOutExpo', function () {
        //        // Set hash in the address bar because of the hash bang
        //        window.location.hash = self.hash;
        //      });
        //
        //      return false;
        //    }
        //  }
        //});

        //Handle hash bangs
        //if (window.location.hash) {
        //  var hash = window.location.hash.replace(/\!\//, '');
        //  var target = $(hash);
        //
        //  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        //
        //  if (target.length) {
        //    $('html,body').animate({
        //      scrollTop: target.offset().top
        //    }, 0, 'easeInOutExpo');
        //
        //    return false;
        //  }
        //}

        //Bootstrap Scroll Spy
        $('[data-spy="scroll"]').each(function () {
            var $spy = $(this).scrollspy('refresh');
        });

        //Bxslider -see options at http://bxslider.com/
        $('.portfolio-itens').bxSlider({
            slideWidth: 200,
            minSlides: 1,
            maxSlides: 4,
            moveSlides: 1,
            slideMargin: 5,
            auto: false,
            mode: 'horizontal',
            useCSS: false,
            speed: 900,
            infiniteLoop: false,
            hideControlOnEnd: true,
            easing: 'easeOutElastic',
            pager: false,
            prevText: '<i class="fa fa-chevron-left"></i>',
            nextText: '<i class="fa fa-chevron-right"></i>'
        });


        //Nivo Lightbox
        $('a.nivobox').nivoLightbox({effect: 'fade'});

        //Portfolio Animations
        $('.portfolio-item').hover(function () {
            $(this).find('.hover-bg-wrapper').fadeIn(200);
            $(this).find('.hover').show();
            $(this).find('p').addClass('animated').addClass('fadeInUp');
        }, function () {
            $(this).find('.hover-bg-wrapper').fadeOut(200);
            $(this).find('.hover').fadeOut(200);
            $(this).find('p').removeClass('fadeInUp');
        });

        //Contact Form Validator and Ajax Sender
        $("#contactForm").validate({
            submitHandler: function (form) {
                var form_obj = $(form);

                $.ajax({
                    type: "POST",
                    url: form_obj.attr('action'),
                    data: form_obj.serialize(),
                    dataType: "json",
                    success: function (data) {
                        $("#contactSuccess").fadeIn(300);
                        $("#contactError").addClass("hidden");

                        $(".form-control", form_obj)
                            .val('')
                            .blur()
                            .removeClass("success")
                            .removeClass("error");
                    },
                    error: function () {
                        $("#contactError").fadeIn(300);
                        $("#contactSuccess").addClass("hidden");
                    }
                });
            }
        });

        //Modal for Contact Form
        $('.modal-wrap').click(function () {
            $('.modal-wrap').fadeOut(300);
        });

        //Background Height fix for vertical progress
        $(".full-height").each(function () {
            var $stretch = $(this);
            $stretch.css({height: $stretch.closest('.line').find('.content-wrap').height()});
        });

    });

    //Run on Window Load
    $(window).load(function () {
        //Page loader
        $('#page-loader').fadeOut(200, function () {
        });

        //Safari Crossbrowser animation Fix
        if ($('html').hasClass('safari')) {
            $('#content-body').removeClass('animated');
        }

        //Fade In load
        $('#content-body').addClass('fadeInUp');

        //Background Height fix for vertical progress
        setTimeout(function () {
                $(".full-height").each(function () {
                    var $stretch = $(this);
                    $stretch.css({height: $stretch.closest('.line').find('.content-wrap').outerHeight()});
                });
            }, 300
        );

        //Background Height fix for vertical progress on window resize
        $(window).resize(function () {
            $(".full-height").each(function () {
                var $stretch = $(this);
                $stretch.css({height: $stretch.closest('.line').find('.content-wrap').outerHeight()});
            });
        });
    });

    $(window).bind("load", function () {

        console.info('Loading styles...');

        var h = document.getElementsByTagName('head')[0];
        var b = document.getElementsByTagName('body')[0];

        var l = document.createElement('link');
        l.rel = 'stylesheet';
        l.href = 'css/all.css';

        h.parentNode.insertBefore(l, h);

        console.info('Loading Google analytics...');

        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

        ga('create', 'UA-69517970-2', 'auto');
        ga('send', 'pageview');

        console.info('Loading Facebook SDK...');

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.async = 1;
            js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5&appId=924256334318222";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        console.info('Loading Twitter widgets...');

        !function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0], p = /^http:/.test(d.location) ? 'http' : 'https';
            if (!d.getElementById(id)) {
                js = d.createElement(s);
                js.id = id;
                js.async = 1;
                js.src = p + '://platform.twitter.com/widgets.js';
                fjs.parentNode.insertBefore(js, fjs);
            }
        }(document, 'script', 'twitter-wjs');

        console.info('Loading LinkedIn...');

        var linkedin = document.createElement('script');
        linkedin.src = '//platform.linkedin.com/in.js';
        linkedin.type = 'text/javascript';
        linkedin.async = 1;
        linkedin.innerHTML = 'lang: en_US';

        b.appendChild(linkedin);

        console.info('Loading Pinterest...');

        var pinterest = document.createElement('script');
        pinterest.src = '//assets.pinterest.com/js/pinit.js';
        pinterest.type = 'text/javascript';
        pinterest.async = 1;

        b.appendChild(pinterest);

    });

})(jQuery);